/* eslint-disable @typescript-eslint/no-floating-promises */
import GET_REPORT_EMBED_TOKEN from "@/graphql/queries/report/getReportEmbedToken";
import { withoutNulls } from "@/utils/withoutNulls";
import { useQuery } from "@apollo/client";
import { useMemo } from "react";

export default function useReportEmbedToken() {
  const { data: raw, loading, error } = useQuery(GET_REPORT_EMBED_TOKEN);

  const normalized = useMemo(() => {
    const data = withoutNulls(raw?.reportEmbedToken);
    return data;
  }, [raw]);

  return { data: normalized, loading, error };
}
