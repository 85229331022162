import { useEffect, useState, type ReactElement } from "react";
import { useRouter } from "next/router";
import { Role } from "@/model/Role";
import ReportsView from "@/views/Report";
import useCurrentUser from "@/graphql/hooks/user/useUser";
import RenewalOfferProvider from "@/context/renewal-offer/context";
import RenewalOfferView from "@/views/RenewalOffers";
import Loader from "@/components/Loader";
import type { NextPageWithLayout } from "./_app";
import Layout from "../layouts/WithNav";

const RenewalOffers: NextPageWithLayout = () => (
  <RenewalOfferProvider>
    <RenewalOfferView />
  </RenewalOfferProvider>
);

const Reports: NextPageWithLayout = ({
  loginOccurred,
}: {
  loginOccurred?: boolean;
}) => <ReportsView loginOccurred={loginOccurred} />;

const Home: NextPageWithLayout = () => {
  const router = useRouter();
  const { data: currentUser, loading } = useCurrentUser();
  const [executive, setExecutive] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    if (currentUser) {
      setExecutive(currentUser.role === Role.EXECUTIVE);
      if (executive) {
        void router.replace("/reports");
      } else {
        void router.replace("/renewal-offers");
      }
    }
  }, [router, currentUser, loading, executive]);
  if (!currentUser) return <Loader />;
  return executive ? <Reports /> : <RenewalOffers />;
};

Home.getLayout = function getLayout(page: ReactElement) {
  return (
    <Layout hasBackgroundColor={false} noSidePadding>
      {page}
    </Layout>
  );
};

export default Home;
